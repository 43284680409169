import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import File from '../File/File';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    'I\u0027m Shirley, I\u0027m an accountant an tax manager, with interest in digital marketing and UI/UX design. I consider myself a curious and fun person, enjoy to be myself. I love music, technology, traveling and spend time with my boyfriend.'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    'I appreciate art. I play ukulele and piano while singing. Something interesting about me is that I\u0027m interested in learning many things. I have my own small business, It\u0027s fun and enjoy what I do.'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || 'I\u0027m really happy about all this. :D'}
                </p>
                {resume && <File filename={resume} />}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
