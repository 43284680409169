import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const File = ({ filename }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile {
          edges {
            node {
              name
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => {
      const file = data.allFile.edges.find((n) => n.node.name.includes(filename));

      if (!file) return null;

      const filePath = file.node.publicURL;
      return (
        <span className="d-flex mt-3">
          <a className="cta-btn cta-btn--resume" href={filePath} download>
            Resume
          </a>
        </span>
      );
    }}
  />
);

File.propTypes = {
  filename: PropTypes.string,
};

export default File;
